/* eslint-disable */
import { ref, watch, computed } from "@vue/composition-api";
export default function useopportunitiesList() {
  const refopportunityListTable = ref(null);

  const perPage = ref(10);
  const totalopportunities = ref(0);
  const currentPage = ref(1);

  const dataMeta = computed(() => {
    const localItemsCount = refopportunityListTable.value
      ? refopportunityListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalopportunities.value,
    };
  });

  return {
    perPage,
    currentPage,
    totalopportunities,
    dataMeta,
    refopportunityListTable,
  };
}
