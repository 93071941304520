<template>
  <div>
    <opportunity-list-add-new
      :is-add-new-opportunity-sidebar-active.sync="
        isAddNewOpportunitySidebarActive
      "
      :customer-options="customerOptions"
      :userOptions="userOptions"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
            <div
              v-bind:class="{
                'align-items-center justify-content-end': isActive,
                'd-flex align-items-center justify-content-end': !isActive,
              }"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="searchQuery"
                  @keyup.enter="searchWord()"
                  :placeholder="$t('message.bookmark.search')"
                />
                <b-input-group-append>
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    @click="searchWord()"
                  >
                    <feather-icon icon="SearchIcon" />
                  </b-button>
                </b-input-group-append>
              </b-input-group>

              <div v-if="restrictions('button_chart_opportunity')">
                <div class="mr-1">
                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    class="btn-icon"
                    :to="{ name: 'apps-chart-opportunity-status' }"
                  >
                    <feather-icon icon="PieChartIcon" />
                  </b-button>
                </div>
              </div>

              <div class="d-flex align-items-center" style="gap: 1rem">
                <div class="ecommerce-header-items">
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    <template slot="button-content">
                      <feather-icon icon="FilterIcon" />
                      {{ $t("message.customers.status") }}
                    </template>
                    <b-dropdown-item
                      @click="setFilter('status', 'all')"
                      :class="{ 'active-filter': activeFilter === 'all' }"
                    >
                      {{ $t("message.projects.all") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="setFilter('status', 'Venceu')"
                      :class="{ 'active-filter': activeFilter === 'Venceu' }"
                    >
                      {{ $t("message.opportunities.won") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="setFilter('status', 'Perdeu')"
                      :class="{ 'active-filter': activeFilter === 'Perdeu' }"
                    >
                      {{ $t("message.opportunities.lost") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      @click="setFilter('status', 'Pendente')"
                      :class="{ 'active-filter': activeFilter === 'Pendente' }"
                    >
                      {{ $t("message.opportunities.pending") }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
               <div class="ecommerce-header-items">
                  <b-dropdown
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                  >
                    <template slot="button-content">
                      <feather-icon icon="FilterIcon" />
                      {{getColLabel("accountable")}}
                    </template>
                    <b-dropdown-item
                      @click="setFilter('responsible', 'all')"
                      :class="{ 'active-filter': activeFilterResponsible === 'all' }"
                    >
                      {{ $t("message.projects.all") }}
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-for="(option, index) in userOptions"
                      @click="setFilter('responsible', option.value)"
                      :class="{ 'active-filter': activeFilterResponsible === option.value }"
                      :key="index"
                    >
                      {{ option.label }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>

              <div class="ml-1">
                <div class="filter-date">
                  <date-range-picker
                    :date-range="dateRange"
                    @update="onDateRangeChange"
                    placeholder="Selecione o intervalo de datas"
                    :locale-data="locale"
                    :key="$i18n.locale"
                    :ranges="getCalendarRange()"
                    class="w-100"
                  >
                    <template v-slot:input="picker">
                      <feather-icon icon="CalendarIcon" />
                      <span class="filter-date-label">
                        {{ formatDate(picker.startDate) }} -
                        {{ formatDate(picker.endDate) }}</span
                      >
                    </template>
                  </date-range-picker>
                </div>
              </div>

              <br v-if="isActive" />
              <b-dropdown
                right
                variant="gradient-warning"
                class="ml-1 mr-1"
                v-if="restrictions('button_export_data')"
              >
                <template slot="button-content">
                  <feather-icon icon="DownloadIcon" />
                </template>
                <b-dropdown-item
                  ><download-excel
                    :data="opportunityList"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.dataInView')"
                  >
                    {{ $t("message.buttons.dataInView") }}
                  </download-excel></b-dropdown-item
                >
                <b-dropdown-item
                  ><download-excel
                    :fetch="allData"
                    :before-generate="startDownload"
                    :before-finish="finishDownload"
                    :name="$t('message.buttons.allData')"
                  >
                    {{ $t("message.buttons.allData") }}
                  </download-excel></b-dropdown-item
                >
              </b-dropdown>

              <b-button
                variant="primary"
                @click="isAddNewOpportunitySidebarActive = true"
                v-if="restrictions('button_new_opportunity')"
              >
                <span class="text-nowrap">{{
                  $t("message.buttons.newOpportunity")
                }}</span>
              </b-button>
            </div>
      </div>

      <b-table
        id="my-table"
        ref="refopportunityListTable"
        class="position-relative"
        :items="opportunityList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :busy="isBusy"
        show-empty
        @row-clicked="openViewPage"
        hover
      >
        <template #table-busy>
          <div class="text-center my-17">
            <b-spinner class="align-middle"></b-spinner>
            <strong> {{ $t("message.load") }}...</strong>
          </div>
        </template>

        <template slot="empty">
          <div class="text-center my-5">
            {{ $t("message.registerNotFound") }}
          </div>
        </template>

        <!-- Column: Name -->
        <template #cell(Nome)="data">
          {{ data.item.name }}
        </template>

        <!-- Column: Description -->
        <template #cell(Descrição)="data">
          <span v-html="data.item.description"></span>
        </template>

        <!-- Column: Value -->
        <template #cell(Valor)="data">
          <a v-if="data.item.international">
            {{
              $n(
                (
                  parseFloat(data.item.value) *
                  parseFloat(data.item.internationalPrice)
                ).toString(),
                "currency"
              )
            }}
          </a>
          <a v-else>
            {{ $n(data.item.value, "currency") }}
          </a>
        </template>

        <!-- Column: CreationDate -->
        <template #cell(Criação)="data">
          {{ localeDate(data.item.dateCreation) }}
        </template>

        <!-- Column: ConclusionDate -->
        <template #cell(Conclusão)="data">
          {{ localeDate(data.item.dateConclusion) }}
        </template>

        <!-- Column: Creator -->
        <template #cell(Criador)="data">
          {{ data.item.createdUser }}
        </template>

        <!-- Column: Creator -->
        <template #cell(Estado)="data">
          <b-badge :variant="badgeColor(data.item.status)">
            {{ opportunityStatus(data.item.status) }}
          </b-badge>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t("message.pagelength") }} {{ currentPage }}
              {{ $t("message.of") }} {{ dataMeta.to }} {{ $t("message.of") }}
              {{ totalRegisters }} {{ $t("register") }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            v-if="showPagination"
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRegisters"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              aria-controls="my-table"
              @input="eventPage(currentPage)"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useopportunitiesList from "./useOpportunitiesList";
import OpportunityListAddNew from "./OpportunityListAddNew.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import JsonExcel from "vue-json-excel";
import Vue from "vue";
import AccessControl from "@core/utils/access-control";
import ListWidth from "@core/utils/listWidth";
import store from "@/store";
Vue.component("downloadExcel", JsonExcel);
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import DateRangePicker from "vue2-daterange-picker";
import getTranslations from "@core/utils/dateRangePicker";

export default {
  components: {
    OpportunityListAddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BSpinner,
    quillEditor,
    DateRangePicker,
  },

  directives: {
    Ripple,
  },

  watch: {
    "$i18n.locale": function (newLocale) {
      this.locale = getTranslations(this.$i18n.locale);
    },
  },

  data() {
    const today = new Date();
    const year = today.getFullYear();

    return {
      dateRange: {
        startDate: new Date(year, 0, 1), // Primeiro dia do ano
        endDate: new Date(year, 11, 31), // Último dia do ano
      },
      dateRangePickerVisible: false,
      locale: getTranslations(this.$i18n.locale),
      activeFilter: "all",
      opportunityList: [],
      totalRegisters: 0,
      perPage: 0,
      currentPage: 1,
      isBusy: false,
      showPagination: true,
      allDataSize: 9999,

      opportunityListAll: [],

      //Select
      customerOptions: [],
      userOptions: [],

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      //Resize
      windowWidth: window.innerWidth,
      isActive: true,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      filter: {
        responsible: "all",
        status: "all",
      },

      activeFilterResponsible: "",
    };
  },

  computed: {
    tableColumns() {
      const lang = this.$i18n.locale;
      if (!lang) {
        return [];
      }

      return [
        { key: "Nome", sortable: false, label: this.getColLabel("name") },
        {
          key: "Descrição",
          sortable: false,
          label: this.getColLabel("description"),
        },
        { key: "Valor", sortable: false, label: this.getColLabel("value") },
        { key: "Criação", sortable: false, label: this.getColLabel("start") },
        {
          key: "Conclusão",
          sortable: false,
          label: this.getColLabel("end"),
        },
        {
          key: "Criador",
          sortable: false,
          label: this.getColLabel("creator"),
        },
        {
          key: "userId",
          sortable: false,
          label: this.getColLabel("accountable"),
        },
        {
          key: "Estado",
          sortable: false,
          label: this.getColLabel("status"),
        },
      ];
    },
  },

  async created() {
    this.onResize();
    await this.getEmployeeList();
    await this.getDataOpportunity();

    var customerList = [];
    var customerId = [];
    await axios
      .get(`${URL_API}customer/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i].name;
          const id = response.data[i].id;
          customerList.push(element);
          customerId.push(id);
          this.customerOptions.push({
            label: customerList[i],
            value: customerId[i],
          });
        }
      });
  },

  mounted() {
    this.$root.$on("newListOpportunity", (newListOpportunity) => {
      this.isBusy = true;

      axios
        .get(
          `${URL_API}opportunity/list?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}`,
          {
            headers: getHeader(this.userData),
          }
        )

        .then((response) => {
          this.opportunityList = response.data.content;
          this.totalRegisters = response.data.totalElements;
          this.perPage = response.data.numberOfElements;
          this.currentPage = 1;
          this.isBusy = false;
        });
    });
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  methods: {
    async getAllOpportunitiesList() {
      await axios
        .get(
          `${URL_API}opportunity/list?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.allDataSize}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          const filteredByDateResponse = this.filterResponseByDate(
            response.data.content
          );

          this.opportunityListAll = filteredByDateResponse;

          this.opportunityListAll.forEach((opportunity) => {
            const userId = opportunity.userId;
            opportunity.userId = this.getUserEmail(userId);
          });
        });
    },

    async wonOpportunities() {
      this.activeFilter = "won";
      this.isBusy = true;
      this.opportunityList = [];

      if (this.opportunityListAll != null) {
        await this.getAllOpportunitiesList();
      }

      for (var i = 0; i < this.opportunityListAll.length; i++) {
        if (this.opportunityListAll[i].status === "Venceu") {
          this.opportunityList.push(this.opportunityListAll[i]);
        }
      }
      this.isBusy = false;
    },

    async lostOpportunities() {
      this.activeFilter = "lost";
      this.isBusy = true;
      this.opportunityList = [];

      if (this.opportunityListAll != null) {
        await this.getAllOpportunitiesList();
      }

      for (var i = 0; i < this.opportunityListAll.length; i++) {
        if (this.opportunityListAll[i].status === "Perdeu") {
          this.opportunityList.push(this.opportunityListAll[i]);
        }
      }
      this.isBusy = false;
    },

    async pendingOpportunities() {
      this.activeFilter = "pending";
      this.isBusy = true;
      this.opportunityList = [];

      if (this.opportunityListAll != null) {
        await this.getAllOpportunitiesList();
      }

      for (var i = 0; i < this.opportunityListAll.length; i++) {
        if (
          this.opportunityListAll[i].status === "Pendente" ||
          !this.opportunityListAll[i].status
        ) {
          this.opportunityList.push(this.opportunityListAll[i]);
        }
      }
      this.isBusy = false;
    },

    async allOpportunities() {
      this.activeFilter = "all";
      this.isBusy = true;
      this.opportunityList = [];

      if (this.opportunityListAll != null) {
        await this.getAllOpportunitiesList();
      }

      for (var i = 0; i < this.opportunityListAll.length; i++) {
        this.opportunityList.push(this.opportunityListAll[i]);
      }
      this.isBusy = false;
    },

    async allData() {
      const response = await axios.get(
        `${URL_API}opportunity/list?page=0&sort=id,${this.paramData.listSort}&size=${this.allDataSize}`,
        { headers: getHeader(this.userData) }
      );
      return response.data.content;
    },

    badgeColor(value) {
      if (value === "Venceu") {
        return "success";
      }
      if (value === "Perdeu") {
        return "danger";
      }
      if (value === "Pendente") {
        return "warning";
      }
      return "warning";
    },

    opportunityStatus(status) {
      if (status === "Venceu") {
        return this.$i18n.t("message.opportunities.won");
      }
      if (status === "Perdeu") {
        return this.$i18n.t("message.opportunities.lost");
      }
      if (status === "Pendente") {
        return this.$i18n.t("message.opportunities.pending");
      }
      return this.$i18n.t("message.opportunities.pending");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.list." + colName);
    },

    onResize() {
      this.windowWidth = window.innerWidth;
      this.isActive = ListWidth(this.windowWidth);
    },

    openViewPage(row) {
      row._showDetails = !row._showDetails;
      this.$router.push({
        name: "apps-opportunity-view",
        params: { id: row.id },
      });
    },

    startDownload() {
      this.isBusy = true;
    },

    finishDownload() {
      this.isBusy = false;
    },

    restrictions(value) {
      return AccessControl(value);
    },

    async setFilter(type, value) {
      this.filter[type] = value;
      await this.getDataOpportunity()

      switch (type) {
        case "status":
          this.activeFilter = value;
        break;
        case "responsible":
          this.activeFilterResponsible = value;
        break;
      }
    },
    async getDataOpportunity() {
      this.isBusy = true;
      const queryParams = new URLSearchParams();
        const filter = this.filter
          for (const key in filter) {
              if (filter) {
                  queryParams.append(key, filter[key]);
              }
          }

      await axios
        .get(
          `${URL_API}opportunity/list?page=0&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}&${queryParams.toString()}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          this.totalRegisters = response.data.totalElements;
          this.opportunityList = response.data.content;
          this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });

      if (this.opportunityList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
        this.currentPage = 1;

        this.opportunityList.forEach((opportunity) => {
          const userId = opportunity.userId;
          opportunity.userId = this.getUserEmail(userId);
        });
      }
    },
    async eventPage(currentPage) {
      console.log("eventPage", currentPage);
      this.isBusy = true;
      const queryParams = new URLSearchParams();
        const filter = this.filter
          for (const key in filter) {
              if (filter) {
                  queryParams.append(key, filter[key]);
              }
          }

      await axios
        .get(
          `${URL_API}opportunity/list?page=${currentPage - 1}&sort=id,${this.paramData.listSort}&size=${this.paramData.tableSize}&${queryParams.toString()}`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          console.log(" response.data.numberOfElements",  response.data.numberOfElements)
        this.totalRegisters = response.data.totalElements;
          this.opportunityList = response.data.content;
        //  this.perPage = response.data.numberOfElements;
          this.isBusy = false;
        });

      if (this.opportunityList === null) {
        this.showPagination = false;
      } else {
        this.showPagination = true;
      }
    },

    async getOpportunityFilter() {
      this.isBusy = true;
      await axios
        .get(`${URL_API}opportunity/search?searchTerm=${this.searchQuery}`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.opportunityList = [];
          if (response.data != []) {
            for (var i = 0; i < response.data.length; i++) {
              this.opportunityList.push(response.data[i]);
            }
          }
          this.isBusy = false;
          this.showPagination = false;
        });
    },

   async searchWord() {
      if (this.searchQuery) {
        this.getOpportunityFilter();
      } else {
       await this.getDataOpportunity()
      }
    },

    localeDate(value) {
      if (value) {
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },
    async onDateRangeChange(dateRange) {
      this.dateRange = {
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      };

      const startDateFormatted = encodeURIComponent(dateRange.startDate.toISOString());
      const endDateFormatted = encodeURIComponent(dateRange.endDate.toISOString())

      const response = await axios.get(
        `${URL_API}opportunity/date?startDate=${startDateFormatted}&endDate=${endDateFormatted}`,
        { headers: getHeader(this.userData) }
      );

      this.opportunityList = response.data.content;
      this.totalRegisters = response.data.totalElements;
      this.perPage = response.data.numberOfElements;
      this.currentPage = 1;
      this.isBusy = false;

      return;

      switch (this.activeFilter) {
        case "all":
          this.allOpportunities();
          break;
        case "won":
          this.wonOpportunities();
          break;
        case "lost":
          this.lostOpportunities();
          break;
        case "pending":
          this.pendingOpportunities();
          break;
      }
    },
    formatDate(date) {
      if (!date) return "";

      const options = { year: "numeric", month: "2-digit", day: "2-digit" };
      return new Intl.DateTimeFormat("pt-BR", options).format(date);
    },

    getCalendarRange() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      return {
        [this.$t("dateFilter.today")]: [today, today],
        [this.$t("dateFilter.yesterday")]: [yesterday, yesterday],
        [this.$t("dateFilter.thisMonth")]: [thisMonthStart, thisMonthEnd],
        [this.$t("dateFilter.thisYear")]: [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31),
        ],
        [this.$t("dateFilter.lastMonth")]: [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
      };
    },

    filterResponseByDate(response) {
      const startDate = this.dateRange.startDate;
      const endDate = this.dateRange.endDate;
      let filteredResponse = [];

      if (startDate && endDate) {
        filteredResponse = response.filter((item) => {
          const itemStartDate = new Date(item.dateCreation);
          const itemEndDate = new Date(item.dateConclusion);

          return itemStartDate >= startDate && itemEndDate <= endDate;
        });

        return filteredResponse;
      } else {
        return response;
      }
    },

    async getEmployeeList() {
      await axios
        .get(
          `${URL_API}user/manager-commercial`,
          {
            headers: getHeader(this.userData),
          }
        )
        .then((response) => {
          var users = response.data;

          users.forEach((user) => {
            const { email, id } = user;

            this.userOptions.push({
              label: email,
              value: id,
            });
          });
        });
    },

    getUserEmail(id) {
      let userEmail = id; // Valor padrão, caso nenhum usuário seja encontrado

      this.userOptions.forEach((user) => {
        if (user.value === id) {
          userEmail = user.label;
          return;
        }
      });

      return userEmail;
    },
  },

  setup() {
    const isAddNewOpportunitySidebarActive = ref(false);

    const {
      totalopportunities,
      dataMeta,
      searchQuery,
      refopportunityListTable,
    } = useopportunitiesList();

    return {
      isAddNewOpportunitySidebarActive,
      totalopportunities,
      dataMeta,
      searchQuery,
      refopportunityListTable,
      avatarText,
    };
  },
};
</script>

<style lang="scss" scoped>
.filter-date-label {
  font-size: 0.875rem !important;
}

.per-page-selector {
  width: 90px;
}

.my-17 {
  margin-top: 17.2rem;
  margin-bottom: 17.2rem;
}

.filter-date {
  min-width: 15rem !important;
  width: 16rem;
  margin-top: 0.4rem;
}

.filter-date-label {
  margin-left: 0.5rem;
  font-size: 15px;
}

.active-filter {
  background-color: #f5f5f5 !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
