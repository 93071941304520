export default function dateByLanguage(i18n, date) {
  if (i18n === "en") {
    var newDate = date.split("/");
    var newDateObject = new Date(+newDate[2], newDate[0] - 1, +newDate[1]);
    return new Date(newDateObject);
  } else {
    var newDate = date.split("/");
    var newDateObject = new Date(+newDate[2], newDate[1] - 1, +newDate[0]);
    return new Date(newDateObject);
  }
}
