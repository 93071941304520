<template>
  <b-sidebar
    id="add-new-opportunity-sidebar"
    :visible="isAddNewOpportunitySidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="
      (val) => $emit('update:is-add-new-opportunity-sidebar-active', val)
    "
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ $t("message.opportunities.createNewOpportunity") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitNewopportunity())"
          @reset.prevent="resetForm"
        >
          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="nome"
            rules="required"
          >
            <b-form-group
              label-for="name"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.tableHeader.name") }}
              </template>
              <b-form-input
                id="name"
                v-model="opportunityData.name"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            #default="validationContext"
            name="decrição"
            rules="required"
          >
            <b-form-group
              label-for="description"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.description") }}
              </template>
              <quill-editor
                id="title"
                style="margin-bottom: 82px; height: 300px"
                :options="editorOption"
                v-model="opportunityData.description"
              />
            </b-form-group>
          </validation-provider>

          <!-- Customer Id -->
          <validation-provider
            #default="validationContext"
            name="cliente"
            rules="required"
          >
            <b-form-group
              label-for="customer"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.projects.customer") }}
              </template>
              <v-select
                v-model="opportunityData.customerId"
                :options="customerOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- User Id -->
          <validation-provider
            #default="validationContext"
            name="cliente"
            rules="required"
          >
            <b-form-group
              label-for="customer"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label> {{$t("message.list.accountable")}} </template>
              <v-select
                v-model="opportunityData.userId"
                :options="userOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract-role"
              />
            </b-form-group>
          </validation-provider>

          <!-- Value -->
          <validation-provider
            #default="validationContext"
            name="valor"
            rules="required"
          >
            <b-form-group
              label-for="value"
              :state="getValidationState(validationContext)"
            >
              <template v-slot:label>
                {{ $t("message.expenses.value") }}
              </template>
              <b-form-input
                id="value"
                v-money="getCurrencyFormat()"
                v-model="opportunityData.value"
                format="currency"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- International -->
          <b-form-group label-for="deadlineTasks">
            <template v-slot:label>
              {{ $t("message.expenses.international") }}
            </template>
            <b-form-checkbox
              v-model="internationalOpportunity"
              name="is-vertical-menu-collapsed"
            />
          </b-form-group>

          <!-- International Value -->

          <b-form-group label-for="value" v-if="internationalOpportunity">
            <template v-slot:label>
              {{ $t("message.expenses.price") }}
            </template>
            <b-form-input
              id="value"
              v-model="opportunityData.internationalPrice"
              v-money="getCurrencyFormat()"
            />
          </b-form-group>

          <!-- Creation Date -->
          <validation-provider
            #default="validationContext"
            name="Criação"
            rules="required"
          >
            <b-form-group label-for="date">
              <template v-slot:label>
                {{ $t("message.tableHeader.start") }}
              </template>
              <flat-pickr
                v-model="opportunityData.dateCreation"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Final Date -->
          <validation-provider
            #default="validationContext"
            name="Conclusão"
            rules="required"
          >
            <b-form-group label-for="conclusionDate">
              <template v-slot:label>
                {{ $t("message.date.end") }}
              </template>
              <flat-pickr
                v-model="opportunityData.dateConclusion"
                class="form-control"
                :config="flatConfig"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                <template>
                  {{ $t("message.list.fieldRequired") }}
                </template>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="!disabledButton"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-else
              disabled
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
            >
              {{ $t("message.buttons.create") }}
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t("message.buttons.cancel") }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
/* eslint-disable */
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormSpinbutton,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import URL_API from "@core/utils/env";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import date from "@/@core/utils/date";
import dateInput from "@/@core/utils/dateInput";
import currency from "@/@core/utils/currency";
import { VMoney } from "v-money";
import store from "@/store";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,
    BFormSpinbutton,
    BFormTextarea,
    formValidation,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  directives: {
    Ripple,
    money: VMoney,
  },
  model: {
    prop: "isAddNewOpportunitySidebarActive",
    event: "update:is-add-new-opportunity-sidebar-active",
  },
  props: {
    isAddNewOpportunitySidebarActive: {
      type: Boolean,
      required: true,
    },
    customerOptions: {
      type: Array,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,

      flatConfig: {
        dateFormat: "d/m/Y",
      },

      //Param
      paramData: store.state.user.paramData,
      userData: store.state.user.userData,

      disabledButton: false,

      editorOption: {
        placeholder: "",
        modules: {
          toolbar: [
            ["bold", "italic", "underline"],
            ["code-block"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
          syntax: {
            highlight: (text) => hljs.highlightAuto(text).value,
          },
        },
      },

      internationalOpportunity: false,
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.flatConfig = dateInput(newVal);
    },
  },

  mounted() {
    if (this.$i18n.locale) {
      this.flatConfig = dateInput(this.$i18n.locale);
    }
  },

  async created() {},

  methods: {
    getCurrencyFormat() {
      return currency(this.$i18n.locale);
    },

    submitNewopportunity() {
      this.$refs.refFormObserver.validate().then((success) => {
        if (success) {
          this.disabledButton = true;
          this.onSubmit();

          this.opportunityData.international = this.internationalOpportunity;

          this.opportunityData.status = "Pendente";

          this.opportunityData.dateCreation = date(
            this.$i18n.locale,
            this.opportunityData.dateCreation
          );
          this.opportunityData.dateConclusion = date(
            this.$i18n.locale,
            this.opportunityData.dateConclusion
          );

          if (this.$i18n.locale === "en") {
            this.opportunityData.value = Number(
              this.opportunityData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          } else {
            this.opportunityData.value = this.switchDotsAndCommas(
              this.opportunityData.value
            );
            this.opportunityData.value = Number(
              this.opportunityData.value.replace(/[^0-9\.-]+/g, "")
            ).toFixed(2);
          }

          axios({
            method: "post",
            url: `${URL_API}opportunity`,
            headers: getHeader(this.userData),
            data: this.opportunityData,
          })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: this.$i18n.t("message.toast.create"),
                  icon: "CheckIcon",
                  variant: "success",
                  text: `Oportunidade criada com sucesso`,
                },
              });
            })
            .then(() => {
              this.$root.$emit("newListOpportunity", null);
              this.disabledButton = false;
            })
            .catch((error) => {
              this.disabledButton = false;
              console.log(error);
            });
        }
      });
    },

    switchDotsAndCommas(s) {
      function switcher(match) {
        return match == "," ? "." : ",";
      }
      return s.replaceAll(/\.|\,/g, switcher);
    },
  },

  setup(props, { emit }) {
    const blankopportunityData = {};

    const opportunityData = ref(
      JSON.parse(JSON.stringify(blankopportunityData))
    );
    const resetopportunityData = () => {
      opportunityData.value = JSON.parse(JSON.stringify(blankopportunityData));
    };

    const onSubmit = () => {
      emit("refetch-data");
      emit("update:is-add-new-opportunity-sidebar-active", false);
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetopportunityData);

    return {
      opportunityData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      vSelect,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-opportunity-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
