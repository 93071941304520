// translations.js
const translations = {
  en: {
    direction: "ltr",
    format: "mm/dd/yyyy",
    separator: " - ",
    applyLabel: "Apply",
    cancelLabel: "Cancel",
    weekLabel: "W",
    customRangeLabel: "Custom Range",
    daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    monthNames: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    firstDay: 0,
    today: "Today",
    yesterday: "Yesterday",
    thisMonth: "This Month",
    thisYear: "This Year",
    lastMonth: "Last Month",
  },
  pt: {
    direction: "ltr",
    format: "dd/mm/yyyy",
    separator: " - ",
    applyLabel: "Aplicar",
    cancelLabel: "Cancelar",
    weekLabel: "S",
    customRangeLabel: "Intervalo Personalizado",
    daysOfWeek: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sáb"],
    monthNames: [
      "Jan",
      "Fev",
      "Mar",
      "Abr",
      "Mai",
      "Jun",
      "Jul",
      "Ago",
      "Set",
      "Out",
      "Nov",
      "Dez",
    ],
    firstDay: 0,
    today: "Hoje",
    yesterday: "Ontem",
    thisMonth: "Este Mês",
    thisYear: "Este Ano",
    lastMonth: "Mês Passado",
  },
  es: {
    direction: "ltr",
    format: "dd/mm/yyyy",
    separator: " - ",
    applyLabel: "Aplicar",
    cancelLabel: "Cancelar",
    weekLabel: "S",
    customRangeLabel: "Rango Personalizado",
    daysOfWeek: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
    monthNames: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic",
    ],
    firstDay: 0,
    today: "Hoy",
    yesterday: "Ayer",
    thisMonth: "Este Mes",
    thisYear: "Este Año",
    lastMonth: "Mes Pasado",
  },
};

export default function getTranslations(lang) {
  return translations[lang] || translations.en;
}
